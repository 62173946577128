import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userSignInAction } from "../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
import Navbar from "../component/Navbar";
import Vector from "../images/Vector.png";
import EllipseD from "../images/EllipseD.png";
import EllipseL from "../images/EllipseL.png";
import Footer from "../component/Footer";
import PersonImage from "../images/person.svg";
import CircleIcon from "../images/circleIcon.svg";

import Lock from "../images/Lock.png";
import Auth from "../images/Auth.png";
import DotedCircles from "../images/DotedCircles.png";
import File from "../images/File.png";
import TextFile from "../images/Textfile.png";
import Setting from "../images/Setting.png";
import Card from "../images/Card.png";
import LoginGif from "../images/Login.gif";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  background: "rgba( 255, 255, 255, 0.1 )",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  backdropFilter: "blur(18px)",
  borderRadius: "20px",
  border: "2px solid rgba(255, 255, 255, 0.18)",
  width: "100%",
}));

const StyledImage = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "220px",
  height: "220px",
  position: "absolute",
  zIndex: "-1",
  top: "-5%",
  left: "-10%",
  opacity: "0.7",
});
const StyledImage2 = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "220px",
  height: "220px",
  position: "absolute",
  opacity: "0.7",
  top: "-10%",
  left: "-10%",
});

const Image = styled("img")({
  width: "100%",
  height: "100%",
});

const HeroImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxWidth: "450px", // This ensures it doesn't exceed 450px on larger screens
  [theme.breakpoints.down("sm")]: {
    maxWidth: "300px", // Smaller max-width for small screens
  },
}));

const LogIn = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loading, userInfo } = useSelector(
    (state) => state.signIn
  );
  const [showPassword, setShowPassword] = useState(false);

  const [checked, setChecked] = useState(false);

  const memoizedCheckBox = useMemo(
    () =>
      function (e) {
        setChecked(e.target.checked);
      },
    [checked]
  );

  // console.log(checked);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(userInfo.role === 1 ? "/" : "/");
    }
  }, [isAuthenticated, userInfo, navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      dispatch(userSignInAction(values));
      // actions.resetForm();
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          // background:
          //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
          backgroundColor: "#13072E",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            py: 5,
            position: "relative",
            zIndex: "9",
            mt: {
              xs: "0px",
            },
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
              margin={"0 auto"}
              position={"relative"}
              zIndex={9}
            >
              <StyledImage>
                <Image src={EllipseD} />
              </StyledImage>
              <Typography
                variant="h2"
                color="white"
                fontWeight={700}
                gutterBottom
                sx={{
                  fontSize: { xs: "32px", md: "48px" },
                  display: { xs: "none", md: "block" },
                  textAlign: "center",
                }}
              >
                Welcome Back to
                <br />
                Unified Mentor.
              </Typography>
              <Box variant="div" sx={{ position: "relative" }}>
                {/* <Box
    sx={{
      position: "absolute",
      top: {
        xs: "-15%",
        sm: "-18%",
        md: "-25%",
        lg: "-17%",
      },
      left: {
        xs: "2%",
        sm: "12%",
        md: "-25%",
        lg: "-8%",
      },
    }}
  >
    <HeroImage
      src={CircleIcon}
      alt="illustration"
      className="rotate"
    />
  </Box> */}
                {/* <Box sx={{ position: "absolute", top: "10%", left: "15%" }}>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                    className="rotate"
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "125px",
                          sm: "200px",
                        },
                        height: {
                          xs: "125px",
                          sm: "200px",
                        },

                        border: "2.5px dashed #002646",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          width: "35%",
                          height: "35%",
                          top: "-8%",
                          left: "30%",
                        }}
                      >
                        <HeroImage
                          src={Card}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "35%",
                          height: "35%",
                          top: "60%",
                          right: "-10%",
                        }}
                      >
                        <HeroImage
                          src={File}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "35%",
                          height: "35%",
                          top: "10%",
                          right: "-13%",
                        }}
                      >
                        <HeroImage
                          src={Auth}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "30%",
                          height: "30%",
                          bottom: "-8%",
                          left: "35%",
                        }}
                      >
                        <HeroImage
                          src={Lock}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "30%",
                          height: "30%",
                          top: "60%",
                          left: "-8%",
                        }}
                      >
                        <HeroImage
                          src={TextFile}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "35%",
                          height: "35%",
                          top: "15%",
                          left: "-15%",
                        }}
                      >
                        <HeroImage
                          src={Setting}
                          alt="illustration"
                          className="HalfRotate"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <HeroImage src={PersonImage} alt="illustration" /> */}
                <Box
                  sx={{
                    width: { xs: "100%", md: "80%" },
                    height: { xs: "100%", md: "80%" },
                    mx: "auto",
                    mt: { xs: 4, md: 0 },
                  }}
                >
                  <img
                    src={LoginGif}
                    alt="illustration"
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              </Box>
            </Box>

            <Box width={{ xs: "100%", md: "45%" }} position={"relative"}>
              <StyledImage2>
                <Image src={EllipseL} />
              </StyledImage2>
              <Typography
                variant="h4"
                color="white"
                fontWeight={700}
                zIndex={9}
                position={"relative"}
                sx={{
                  display: { xs: "block", md: "none" },
                  fontSize: { xs: "32px" },
                  textAlign: "center",
                  py: 4,
                }}
              >
                Welcome Back to Unified Mentor.
              </Typography>
              <StyledForm component="form" onSubmit={formik.handleSubmit}>
                <Typography variant="h4" color="white">
                  Login
                </Typography>
                <Typography variant="subtitle1" color="white">
                  Glad you're back!
                </Typography>
                <TextField
                  fullWidth
                  className="form_style_Email"
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                      "&.Mui-focused": {
                        color: "white",
                      },
                    },
                  }}
                />

                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "white" },
                      "&:hover fieldset": { borderColor: "white" },
                      "&.Mui-focused fieldset": { borderColor: "white" },
                    },
                  }}
                >
                  <InputLabel
                    className="form_style_Password"
                    id="password"
                    htmlFor="password"
                    sx={{
                      color: "white",
                      "&.Mui-focused": {
                        color: "white",
                      },
                    }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    sx={{
                      color: "#fff",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: "white" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography color="error" variant="caption">
                      {formik.errors.password}
                    </Typography>
                  )}
                  <FormControlLabel
                    sx={{ color: "white" }}
                    required
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": { color: "white" },
                        }}
                        onChange={memoizedCheckBox}
                      />
                    }
                    label="I agree to terms and conditions"
                  />
                </FormControl>

                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={loading || checked === false}
                  sx={{
                    py: 1.5,
                    background: "#13072E",
                  }}
                >
                  {loading ? <CircularProgress color="inherit" /> : "Log In"}
                </Button>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    variant="body2"
                    component="a"
                    href="https://unifiedmentor.com/terms-and-conditions"
                    target="_blank"
                    color="white"
                  >
                    Terms and Conditions
                  </Typography>
                  <Typography
                    variant="body2"
                    component="a"
                    target="_blank"
                    href="https://unifiedmentor.com/privacy-policy"
                    color="white"
                  >
                    Privacy Policy
                  </Typography>
                </Stack>
              </StyledForm>
            </Box>
          </Stack>
        </Container>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            opacity: 0.5,
            zIndex: 1,
            bottom: "-20%",
          }}
        >
          <img
            src={Vector}
            alt="Vector"
            style={{
              width: "100%",
              height: "120%",
            }}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default LogIn;
